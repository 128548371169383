<div class="container-fluid" style="overflow-x: hidden">
    <!--Jumbotron-->
    <div class="container-fluid header text-white position-relative">

        <video *ngIf="!isSafari" autoplay muted loop>
            <source src="../../../assets/NewImages/Video_1_2 (1).webm" type="video/webm" />
        </video>

        <!-- =========== safari =========== -->
        <video *ngIf="isSafari" muted loop autoplay playsinline preload="yes">
            <source src="../../../assets/NewImages/Video_1_2 (1).mp4" type="video/mp4" />
        </video>

        <nav class="navbar navbar-expand-md navbar-dark scrolling-navbar" style="box-shadow: none">
            <!-- Brand -->
            <a class="navbar-brand mt-2 ml-4" routerLink="/"><img
                    src="../../../assets/NewImages/For_Website_1024x344_V_1.png" alt=""
                    style="transform: scale(1.3); height: 50px" /></a>

            <!-- Toggler/collapsibe Button -->
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar"
                id="btnClick">
                <span class="navbar-toggler-icon"></span>
            </button>

            <!-- Navbar links -->
            <div class="collapse navbar-collapse justify-content-end" id="collapsibleNavbar">
                <ul class="navbar-nav">
                    <li class="nav-item" *ngIf="!authService.loggedIn()">
                        <a class="nav-link text-uppercase" routerLink="/" routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }">HOME
                            <span class="sr-only">(current)</span>
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="authService.loggedIn() && !authService.isPermitted('dgft')">
                        <a class="nav-link text-uppercase fontFOCO" routerLink="/home" routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }">HOME</a>
                    </li>
                    <li class="nav-item" class="nav-item" *ngIf="!authService.loggedIn()">
                        <a class="nav-link text-uppercase fontFOCO" routerLink="/login" routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }">Login</a>
                    </li>
                    <li class="nav-item" class="nav-item" *ngIf="!authService.loggedIn()">
                        <a class="nav-link text-uppercase fontFOCO" routerLink="/register" routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }">Register</a>
                    </li>
                    <li class="nav-item" class="nav-item" *ngIf="!authService.loggedIn()">
                        <a class="nav-link text-uppercase fontFOCO" routerLink="/trackmyparcel/track"
                            routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">track my parcel</a>
                    </li>
                    <li class="nav-item" class="nav-item" *ngIf="!authService.loggedIn()">
                        <a class="nav-link text-uppercase fontFOCO" routerLink="/officers/customs"
                            routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">customs login</a>
                    </li>
                    <li class="nav-item" class="nav-item" *ngIf="
                !authService.loggedIn() ||
                authService.isExporterAdmin() ||
                authService.isSuperAdmin() ||
                authService.isCha()
              ">
                        <a class="nav-link text-uppercase fontFOCO" routerLink="/plans" routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }">Pricing</a>
                    </li>

                    <li class="nav-item" class="nav-item">
                        <a class="nav-link text-uppercase fontFOCO" routerLink="/video-resources" routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }">Resources</a>
                    </li>

                    <li class="nav-item dropdown show" routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }">
                        <a class="nav-link dropdown-toggle fontFOCO" href="#" role="button" id="nab=vbarDropdown"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            HELPDESK
                        </a>

                        <div class="dropdown-menu" aria-labelledby="dropdownMenuLink"
                            [ngClass]="!authService.loggedIn() ? 'dropdownMargin' : ''">
                            <a *ngIf="authService.loggedIn() && authService.isSuperAdmin()" class="dropdown-item"
                                routerLink="/viewtickets" routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }">Tickets</a>
                            <a class="dropdown-item" data-toggle="modal" data-target="#trackShipment"
                                *ngIf="authService.loggedIn()">Track
                                My Parcel</a>
                            <a class="dropdown-item" routerLink="/resources" *ngIf="authService.loggedIn()">Helpful
                                Resources</a>
                            <a class="dropdown-item" routerLink="/helpdesk/Issue">Report an Issue</a>
                            <a class="dropdown-item" routerLink="/helpdesk/DemoRequest">Get a Demo</a>
                            <a class="dropdown-item" routerLink="/helpdesk/FeatureRequest">Suggest a Feature</a>
                            <!-- <a class="dropdown-item" routerLink="/serviceprovider">Service Providers</a> -->
                            <a class="dropdown-item" routerLink="/contact" *ngIf="authService.loggedIn()">Contact us</a>
                        </div>
                    </li>
                    <!-- 
            <li class="noHover" *ngIf="!authService.loggedIn()" (click)="gotoP2p()">
              <a class="text-uppercase fontFOCO"
                style="color: #fcc02d; font-weight: 600; font-size: 12px;padding:9px 5px 2px 5px;">Send a Personal
                Shipment</a>
            </li> -->

                </ul>
            </div>
        </nav>
        <!--Heading and subheading-->
        <div class="head-top">
            <div class="container text-center main-head-div">
                <!--heading-->
                <div>
                    <h4 class=" main-head-export display1-4 font-weight-bold text-uppercase pb-1 fontHalfmoon "
                        style="font-size: 3.7rem">
                        shipping made simple
                    </h4>
                    <!-- <p class="centered-border"></p> -->
                </div>

                <!--subheading-->
                <div class="container text-center pt-0">
                    <h5 class="container fontFOCOBold sub-main-head" style="width: 70%">
                        We help e-commerce business ship globally across 175+ countries
                    </h5>
                </div>
            </div>
            <!--Register-->
            <div class="container text-center mt-2">
                <div class="row pt-2" *ngIf="isSafari">
                    <div class="col-6" style="text-align: end">
                        <button type="button" style="width: 9rem" id="loginId" (mouseover)="iconHover('login')"
                            (mouseout)="iconOut('login')">
                            <a routerLink="/login"><img [src]="register" class="img-fluid" style="width: 62%" /></a>
                        </button>
                    </div>
                    <div class="col-6" style="text-align: start">
                        <button type="button" style="width: 9rem" id="loginId" (mouseover)="iconHover('register')"
                            (mouseout)="iconOut('register')">
                            <a routerLink="/register"><img [src]="login_icon" class="img-fluid"
                                    style="width: 76%" /></a> </button>
                    </div>
                </div>
                <div class="row pt-2" *ngIf="!isSafari">
                    <div class="col-6" style="text-align: end">
                        <button type="button" style="width: 9rem" id="loginId" (mouseover)="iconHover('login')"
                            (mouseout)="iconOut('login')">
                            <a routerLink="/login"><img [src]="register" class="img-fluid" style="width: 62%" /></a>
                        </button>
                    </div>
                    <div class="col-6" style="text-align: start">
                        <button type="button" style="width: 9rem" id="loginId" (mouseover)="iconHover('register')"
                            (mouseout)="iconOut('register')">
                            <a routerLink="/register"><img [src]="login_icon" class="img-fluid"
                                    style="width: 76%" /></a> </button>
                    </div>
                </div>

                <!-- ============= four logos ========== -->
                <!-- notMobile -->
                <div class="row pt-4 ">
                    <div class="col-12 d-flex justify-content-center ">
                        <div><a><img src="../../../assets/login_register/ficci.png" class="img-fluid" /></a></div>
                        <div><a><img src="../../../assets/login_register/digi.png" class="img-fluid" /></a></div>
                        <div><a href="https://play.google.com/store/apps/details?id=com.pikmybox.pmb"><img
                                    src="../../../assets/login_register/playstore.png" class="img-fluid" /></a></div>
                        <div><a href="https://apps.apple.com/us/app/pikmybox/id1473778437?ls=1"><img
                                    src="../../../assets/login_register/appstore.png" class="img-fluid" /></a></div>
                    </div>
                </div>

            </div>



        </div>



        <!-- <div class="container icon-bar-mobile">
        <div class="google-phone-icon pb-4">
          <a href="https://play.google.com/store/apps/details?id=com.pikmybox.pmb">
            <img [src]="google_phone_icon" class="img-fluid" /></a>
        </div>
        <div class="apple-phone-icon pb-4">
          <a href="https://apps.apple.com/us/app/pikmybox/id1473778437?ls=1"><img [src]="apple_phone_icon" class="img-fluid" /></a>
        </div>
      </div> -->

    </div>

    <!-- shipping partners -->
    <div class="container mt-4">
        <div class="row align-items-center justify-content-center">
            <div class="col-12 text-center">
                <!-- <img src="../../../assets/NewImages/Heading_top_icon.png" class="img-fluid mb-4" /> -->
                <h1 class="display-6 font-weight-bold fontHalfmoon word-spacing text-center mb-2">
                    Ship with our carrier partners
                </h1>
            </div>
        </div>


        <div class="row p-1 mt-3 align-items-center justify-content-center">
            <div class="col-10 col-sm-8 col-md-2 col-xl-2 col-xxl-2 mb-2">
                <div class="card cdbh">
                    <div class="card-body">
                        <img style="width: 100%" src="../../../assets/img/carrier_partners/india_post_carrier_logo.png"
                            alt="">
                    </div>
                </div>
            </div>

            <div class="col-10 col-sm-8 col-md-2 col-xl-2 col-xxl-2 mb-2">
                <div class="card cdbh">
                    <div class="card-body">
                        <img style="width: 100%" src="../../../assets/img/carrier_partners/aramex_carrier_logo.png"
                            alt="">
                    </div>
                </div>
            </div>

            <div class="col-10 col-sm-8 col-md-2 col-xl-2 col-xxl-2 mb-2">
                <div class="card cdbh">
                    <div class="card-body">
                        <img style="width: 100%" src="../../../assets/img/carrier_partners/DELHIVERY_logo.png" alt="">
                    </div>
                </div>
            </div>

            <div class="col-10 col-sm-8 col-md-2 col-xl-2 col-xxl-2 mb-2">
                <div class="card cdbh">
                    <div class="card-body">
                        <img style="width: 100%" src="../../../assets/img/carrier_partners/xpressbees_carrier_logo.png"
                            alt="">
                    </div>
                </div>
            </div>

            <div class="col-10 col-sm-8 col-md-2 col-xl-2 col-xxl-2 mb-2">
                <div class="card cdbh">
                    <div class="card-body">
                        <img style="width: 100%" src="../../../assets/img/carrier_partners/fedex_carrier_logo.png"
                            alt="">
                    </div>
                </div>
            </div>

            <!-- <div class="col-2">
                <div class="card cdbh">
                    <div class="card-body">
                        <img style="width: 100%" src="../../../assets/img/carrier_partners/dhl_carrier_logo.png" alt="">
                    </div>
                </div>
            </div> -->

        </div>

        <!-- <div class="row py-2 px-4 text-center align-items-center">
        <div class="col-12">
          <button routerLink="/login" routerLinkActive="active" class="btn btn-primary px-3 py-2"
            style="border-radius: 10px;font-size: 14px;font-weight: 500;">Book Your Shipment Now</button>
        </div>
      </div> -->

    </div>
    <!-- End - shipping parterners -->

    <!-- /******************************* -->
    <div class="container mt-4 mb-3">
        <div class="row align-items-center justify-content-center">
            <div class="col-12 text-center">
                <img src="../../../assets/NewImages/Heading_top_icon.png" class="img-fluid mb-4" />
            </div>
            <div class="col-12 mt-4 ">
                <div class="row align-items-center justify-content-center">

                    <div class="col-5">
                        <div class="card" style="position: relative;height: 40vh;box-shadow: none !important;">
                            <div class="card-body">
                                <!--  -->
                                <video *ngIf="isSafari" autoplay playsinline preload="yes" id="vid1"
                                    (click)="goto('pmb1')" class="vdo">
                                    <source src="../../../assets/landpagevideos/new/Business_laptop.mp4"
                                        type="video/mp4" />
                                </video>
                                <video *ngIf="!isSafari" id="vid1" (click)="goto('pmb1')" class="vdo">
                                    <source src="../../../assets/landpagevideos/new/Business_laptop.webm"
                                        type="video/webm" />
                                </video>
                                <!--  -->
                                <button (click)="goto('pmb1')" class="btn btn-primary px-3 py-2 fltbtn">
                                    Ship to a customer
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="col-5 offset-1">
                        <div class="card" style="position: relative;height: 40vh;box-shadow: none !important;">
                            <div class="card-body">
                                <!--  -->
                                <video *ngIf="isSafari" autoplay playsinline preload="yes" id="vid2"
                                    (click)="goto('pmb')" class="vdo">
                                    <source src="../../../assets/landpagevideos/new/drone_laptop.mp4"
                                        type="video/mp4" />
                                </video>
                                <video *ngIf="!isSafari" id="vid2" alt="" (click)="goto('pmb')" class="vdo">
                                    <source src="../../../assets/landpagevideos/new/p2plaptop.webm" type="video/webm" />
                                </video>
                                <!--  -->
                                <button (click)="goto('pmb')" class="btn btn-primary px-3 py-2 fltbtn"
                                    style="left: 32% !important; ">
                                    Ship to a friend
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <!-- /******************************* -->




    <!--Why PikMyBox-->
    <div class="container-fluid mt-5">
        <div class="container-fluid text-center mb-5">
            <!-- <img src="../../../assets/NewImages/Heading_top_icon.png" class="img-fluid mb-4" /> -->
            <h1 class="display-6 font-weight-bold fontHalfmoon word-spacing">
                Why PikMyBox?
            </h1>

            <h5 class="container fontFOCOBold mt-3 main-head-size" style="width: 60%">
                PikMyBox is India's leading logistics solution provider for e-commerce
                business, enabling them to export through India Post and express
                couriers with ease.
            </h5>
        </div>

        <div class="container-fluid position-relative div-100 mt-4">
            <div class="demo-wrap">
                <img src="../../../assets/NewImages/Why_PMB_1.png" class="img-fluid image-background" />

                <div class="container-fluid div-100 position-relative pl-4">
                    <div class="row seamless-india">
                        <div class="col-sm-1 p-0 div-arrow-parent">
                            <div class="div-arrow"></div>
                        </div>
                        <div class="col-sm-10">
                            <div class="
                    font-weight-bold
                    text-magenta
                    fontFOCOBold
                    main-head-size
                  ">
                                Seamless India Post Experience
                            </div>
                            <div class="text-muted fontFOCOLight sub-head-size">
                                We offer first mile and clearance services and full digital
                                documentation, making India Post a seamless door to door
                                delivery service
                            </div>
                        </div>
                    </div>

                    <div class="row multi-marketplace-support">
                        <div class="col-sm-1 p-0 div-arrow-parent">
                            <div class="div-arrow"></div>
                        </div>
                        <div class="col-sm-10">
                            <div class="font-weight-bold text-grey fontFOCOBold main-head-size">
                                Multi-Marketplace Support
                            </div>
                            <div class="text-muted fontFOCOLight sub-head-size">
                                Pull order data from prominent marketplaces like eBay and
                                Amazon, upload on PikMyBox, and get all your shipping labels and
                                export documents in a single click.
                            </div>
                        </div>
                    </div>

                    <div class="row global-reach">
                        <div class="col-sm-1 p-0 div-arrow-parent">
                            <div class="div-arrow"></div>
                        </div>
                        <div class="col-sm-10">
                            <div class="font-weight-bold text-purple fontFOCOBold main-head-size">
                                Global Reach, Global Partners
                            </div>
                            <div class="text-muted fontFOCOLight sub-head-size">
                                Ship globally across 175+ countries with multiple courier
                                partners like DHL, Aramex, India Post and BVC Vamaship
                            </div>
                        </div>
                    </div>

                    <div class="row export-documents">
                        <div class="col-sm-1 p-0 div-arrow-parent">
                            <div class="div-arrow"></div>
                        </div>
                        <div class="col-sm-10">
                            <div class="font-weight-bold text-yellow fontFOCOBold main-head-size">
                                Export Document Services
                            </div>
                            <div class="text-muted fontFOCOLight sub-head-size">
                                Get PikMyBox support to generate all your documents required for
                                export, whether through India Post or Courier channel
                            </div>
                        </div>
                    </div>

                    <div class="row navigating-export">
                        <div class="col-sm-1 p-0 div-arrow-parent">
                            <div class="div-arrow"></div>
                        </div>
                        <div class="col-sm-10">
                            <div class="font-weight-bold fontFOCOBold main-head-size">
                                Navigating the Export Landscape
                            </div>
                            <div class="text-muted fontFOCOLight sub-head-size">
                                Full stack support with account set up for IEC, AD code,
                                international payment gateways, BNPL for India Post, among
                                others
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!-- ----------- our clients ---------- -->
    <div class="container mt-5">
        <h1 class="display-6 font-weight-bold fontHalfmoon word-spacing text-center mb-2">
            Our Clients
        </h1>

        <div class="row p-4">
            <div class="col-3">
                <img style="width: 100%" src="../../../assets/img/clients/Devans_Logo.png" alt="">
            </div>
            <div class="col-3">
                <img style="width: 100%" src="../../../assets/img/clients/CCIE_logo_x809.png" alt="">
            </div>

            <div class="col-3">
                <img style="width: 100%" src="../../../assets/img/clients/vahdam-teas_x809.png" alt="">
            </div>

            <div class="col-3">
                <img style="width: 100%" src="../../../assets/img/clients/caratlane_logo_x809.png" alt="">
            </div>
        </div>

        <div class="row pt-4">
            <div class="col-3" style="text-align: center;">
                <img style="width: 80%;" src="../../../assets/img/clients/Mettal_logo_x809.png" alt="">
            </div>
            <div class="col-3" style="margin: auto">
                <img style="width: 100%" src="../../../assets/img/clients/spimicl_logo_x809.png" alt="">
            </div>

            <div class="col-3" style="text-align: center;">
                <img style="width: 50%" src="../../../assets/img/clients/Logo_Schwabex809.png" alt="">
            </div>

            <div class="col-3" style="margin: auto;">
                <img style="width: 100%" src="../../../assets/img/clients/nirmal-logo_x809.png" alt="">
            </div>

        </div>

        <div class="row">
            <div class="col-12  pt-2 pb-4" style="text-align: center">
                <h5 style="font-weight: 400;"> And 4000+ more </h5>
            </div>
        </div>
    </div>

    <!-- Testimonials -->
    <div class="container mt-5 text-center">
        <img src="../../../assets/NewImages/Heading_top_icon.png" class="img-fluid mb-4" />
        <h1 class="display-6 font-weight-bold fontHalfmoon word-spacing text-center mb-4">
            Testimonials
        </h1>

        <div class="row">
            <div class="col-md-4">
                <div class="card">
                    <div class="card-body">
                        <!-- <h5 class="card-title">Special title treatment</h5> -->
                        <!-- <div  class="avatar mb-2">
                    <img src="http://localhost:4200/assets/images/logo.png" style="width: 80px;">
                  </div> -->

                        <p class="card-text text-center">
                            <i class="fa fa-quote-left" style="width: 100%;color: #FCC000;font-size: x-small"></i>
                            PikMyBox is helping us a lot. As a woman I feel daily travel to Forign Post is not possible
                            so I use the
                            PikMyBox Pickup service , this will help me in time management too. Also we ship all
                            shipment in handling
                            time and they also update regarding our shipment. We are very happy with your customer
                            service.
                            Thanks a lot PikMyBox
                            <i class="fa fa-quote-right" style="width: 100%;color: #FCC000;font-size: x-small"></i>
                        </p>

                        <div style="text-align: end;">
                            <h5 style="color:#350093"> Varsha (Director) </h5>
                            <small style="color:#ed2d68"> Indo Imperial Art & Craft INC Delhi </small>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <div class="card">
                    <div class="card-body">
                        <!-- <h5 class="card-title">Special title treatment</h5> -->
                        <!-- <div  class="avatar mb-2">
                      <img src="http://localhost:4200/assets/images/logo.png" style="width: 80px;">
                    </div> -->

                        <p class="card-text text-center">
                            <i class="fa fa-quote-left" style="width: 100%;color: #FCC000;font-size: x-small"></i>
                            PikMyBox helps us to export products in easiest way to handle transportation.
                            Any new person can start exporting with PikMyBox with minimum knowledge.
                            <i class="fa fa-quote-right" style="width: 100%; color: #FCC000;font-size: x-small"></i>
                            <br><br><br><br>
                        </p>

                        <div style="text-align: end;">
                            <h5 style="color:#350093"> Deepak Kumar (Director) </h5>
                            <small style="color:#ed2d68"> Kalindri Enterprises (Meerut) </small>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <div class="card">
                    <div class="card-body">
                        <!-- <h5 class="card-title">Special title treatment</h5> -->
                        <!-- <div class="avatar mb-2">
                      <img src="http://localhost:4200/assets/images/logo.png" style="width: 80px;">
                    </div> -->

                        <p class="card-text text-center">
                            <i class="fa fa-quote-left" style="width: 100%;color: #FCC000;font-size: x-small"></i>
                            Without PikMyBox our aim of promotion could not be fulfilled because their dedication
                            towards their job
                            helps us to concentrate on our part of promotion rather than logistics
                            <i class="fa fa-quote-right" style="width: 100%; color: #FCC000;font-size: x-small"></i>
                            <br><br><br><br>
                        </p>

                        <div style="text-align: end;">
                            <h5 style="color:#350093"> Amit (Director) </h5>
                            <small style="color:#ed2d68"> Gyan Books Pvt Ltd Delhi</small>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>



    <!--How To Use-->
    <div class="bg-how-to-use mt-5">
        <div class="container-fluid mt-5 mb-5">
            <!--Heading-->
            <div class="container-fluid text-center">
                <h1 class="font-weight-bold fontHalfmoon text-light word-spacing">
                    How to use PikMyBox?
                </h1>
            </div>
        </div>

        <!--How to use body-->
        <div class="container-fluid mt-5 mb-5 how-to-use">
            <div class="how-to-use-text register-on">
                <div class="fontFOCOBold font-weight-bold text-yellow main-head-size1">
                    Register on
                </div>
                <div class="fontFOCOBold text-white font-weight-bold sub-head-size1">
                    www.pikmybox.com
                </div>
            </div>
            <div class="how-to-use-text register-your-account">
                <h5 class="
              fontFOCOBold
              text-yellow
              font-weight-bold
              main-head-size1
              word-spacing1
            ">
                    Register your account with India Post, DHL and Aramex
                </h5>
                <div class="fontFOCOLight sub-head-size1 text-white" style="text-align: justify">
                    Free and fully assisted registration services. Plus, get expedited KYC
                    and account approvals if you register through PikMyBox
                </div>
            </div>
            <div class="how-to-use-text upload-your-basic">
                <h5 class="
              fontFOCOBold
              font-weight-bold
              main-head-size1
              text-yellow
              word-spacing1
            ">
                    Upload your basic shipment data
                </h5>
                <div class="fontFOCOLight text-white sub-head-size1" style="text-align: justify">
                    Custom upload features for Amazon and Ebay sellers on integrate your
                    e-commerce site
                </div>
            </div>
            <div class="how-to-use-text compare-rates">
                <h5 class="
              fontFOCOBold
              mt-4
              text-yellow
              main-head-size1
              font-weight-bold
              word-spacing1
            ">
                    Compare rates across different shipping partners
                </h5>
                <div class="fontFOCOLight text-white sub-head-size1" style="text-align: justify">
                    Select the best rate for your shipping speed and destination country
                </div>
            </div>
            <div class="how-to-use-text generate-documents">
                <h5 class="
              fontFOCOBold
              font-weight-bold
              main-head-size1
              text-yellow
              word-spacing1
            ">
                    PikMyBox will generate all the documents you need
                </h5>
                <div class="fontFOCOLight text-white sub-head-size1" style="text-align: justify">
                    Shipping labels, invoices, customs declarations and banking forms
                    required by your selected shipping partner
                </div>
            </div>
            <div class="how-to-use-text prepare-dispatch">
                <h5 class="
              fontFOCOBold
              text-yellow
              font-weight-bold
              main-head-size1
              word-spacing1
            ">
                    Prepare and dispatch your shipment
                </h5>
                <div class="fontFOCOLight text-white sub-head-size1" style="text-align: justify">
                    PikMyBox offers first mile services to get your parcels to the nearest
                    FPO or courier hub
                </div>
            </div>
            <div class="how-to-use-text export">
                <div class="
              fontFOCOBold
              mt-4
              font-weight-bold
              text-yellow
              main-head-size1
              word-spacing1
            ">
                    Start Exporting!
                </div>
            </div>
        </div>

        <!-- how to use on mobiles-->
        <div class="container-fluid ml-2 how-to-use-mobile">
            <!-- 1st image-->
            <div class="m-4">
                <h2 class="fontFOCOBold text-yellow how-to-use-numbers font-weight-bold">
                    1
                </h2>
                <div class="text-center">
                    <img src="../../../assets/NewImages/how_to_use/1.png" class="how-to-use-image" />
                    <div class="fontFOCOBold main-head-size1 text-yellow">
                        Register on
                    </div>
                    <div class="fontFOCOBold font-weight-bold main-head-size1 text-white">
                        www.pikmybox.com
                    </div>
                </div>
            </div>
            <!-- 2nd image-->
            <div class="m-4">
                <h2 class="fontFOCOBold text-yellow how-to-use-numbers font-weight-bold">
                    2
                </h2>
                <div class="text-center">
                    <img src="../../../assets/NewImages/how_to_use/2.png" class="how-to-use-image" />
                    <h5 class="fontFOCOBold text-yellow font-weight-bold main-head-size11">
                        Register your account with India Post, DHL and Aramex
                    </h5>
                    <div class="fontFOCOLight sub-head-size text-white">
                        Free and fully assisted registration services Plus, get expedited
                        KYC and account approvals if you register through PikMyBox
                    </div>
                </div>
            </div>
            <!-- 3rd image-->
            <div class="m-4">
                <h2 class="fontFOCOBold text-yellow how-to-use-numbers font-weight-bold">
                    3
                </h2>
                <div class="text-center">
                    <img src="../../../assets/NewImages/how_to_use/3.png" class="how-to-use-image" />
                    <h5 class="fontFOCOBold font-weight-bold main-head-size1 text-yellow">
                        Upload your basic shipment data
                    </h5>
                    <div class="fontFOCOLight text-white sub-head-size1">
                        Custom upload features for Amazon and Ebay sellers on integrate your
                        e-commerce site
                    </div>
                </div>
            </div>
            <!-- 4th image-->
            <div class="m-4">
                <h2 class="fontFOCOBold text-yellow how-to-use-numbers font-weight-bold">
                    4
                </h2>
                <div class="text-center">
                    <img src="../../../assets/NewImages/how_to_use/4.png" class="how-to-use-image" />
                    <h5 class="
                fontFOCOBold
                mt-4
                text-yellow
                font-weight-bold
                main-head-size1
              ">
                        Compare rates across different shipping partners
                    </h5>
                    <div class="fontFOCOLight sub-head-size1 text-white">
                        Select the best rate for your shipping speed and destination country
                    </div>
                </div>
                <div class="how-to-use-text generate-documents">
                    <h5 class="fontFOCOBold font-weight-bold main-head-size text-yellow">
                        PikMyBox will generate all the documents you need
                    </h5>
                    <div class="fontFOCOLight text-white sub-head-size">
                        Shipping labels, invoices customs declarations and banking forms
                        required by your selected shipping partner
                    </div>
                </div>
            </div>
            <!-- 5th image-->
            <div class="m-4">
                <h2 class="fontFOCOBold text-yellow how-to-use-numbers font-weight-bold">
                    5
                </h2>
                <div class="text-center">
                    <img src="../../../assets/NewImages/how_to_use/5.png" class="how-to-use-image" />
                    <h5 class="fontFOCOBold font-weight-bold main-head-size text-yellow">
                        PikMyBox will generate all the documents you need
                    </h5>
                    <div class="fontFOCOLight text-white sub-head-size">
                        Shipping labels, invoices customs declarations and banking forms
                        required by your selected shipping partner
                    </div>
                </div>
            </div>
            <!-- 6th image-->
            <div class="m-4">
                <h2 class="fontFOCOBold text-yellow how-to-use-numbers font-weight-bold">
                    6
                </h2>
                <div class="text-center">
                    <img src="../../../assets/NewImages/how_to_use/6.png" class="how-to-use-image" />
                    <h5 class="fontFOCOBold text-yellow font-weight-bold main-head-size1">
                        Prepare and dispatch your shipment
                    </h5>
                    <div class="fontFOCOLight sub-head-size1 text-white">
                        PikMyBox offers first mile services to get your parcels to the
                        nearest FPO or courier hub
                    </div>
                </div>
            </div>
            <!-- 7th image-->
            <div class="m-4">
                <h2 class="fontFOCOBold text-yellow how-to-use-numbers font-weight-bold">
                    7
                </h2>
                <div class="text-center">
                    <img src="../../../assets/NewImages/how_to_use/7.png" class="how-to-use-image" />
                    <h5 class="
                fontFOCOBold
                mt-4
                font-weight-bold
                main-head-size1
                text-yellow
              ">
                        Start Exporting!
                    </h5>
                </div>
            </div>
        </div>
    </div>

    <!--Our Services-->
    <div class="container-fluid mt-5">
        <div class="container-fluid text-center mb-5">
            <h1 class="display-6 font-weight-bold fontHalfmoon word-spacing">
                Our Services
            </h1>
            <p class="centered-border mt-3"></p>
            <h5 class="container mt-5 fontFOCOBold" style="width: 60%">
                PikMyBox is an easy to use application, which allows exporters to make
                the most competitive choice of a logistics service provider and complete
                all of their export documentation at one go.
            </h5>
        </div>
        <!--Service diagram-->
        <div class="container-fluid position-relative div-100 mt-4">
            <!--image as background-->
            <div class="demo-wrap">
                <img src="../../../assets/NewImages/Our_Services.jpg" class="img-fluid image-background" />
                <div class="container-fluid div-100 position-relative pl-4 margin-services">
                    <div class="row export-forms">
                        <div class="col-sm-11">
                            <div class="
                    font-weight-bold
                    text-magenta
                    main-head-size
                    fontFOCOBold
                  ">
                                First Mile and Warehousing
                            </div>
                            <div class="text-muted fontFOCOLight sub-head-size">
                                First mile services including doorstep pickup and customs
                                clearance to get your shipment to the nearest FPO or courier
                                hub. Warehousing, packing and labeling services are also
                                available so that you can focus on your global sales.
                            </div>
                        </div>
                        <div class="col-sm-1 p-0 div-arrow-parent">
                            <div class="div-arrow"></div>
                        </div>
                    </div>
                    <div class="row cr-engine">
                        <div class="col-sm-11">
                            <div class="font-weight-bold gray main-head-size fontFOCOBold">
                                Export forms
                            </div>
                            <div class="text-muted fontFOCOLight sub-head-size">
                                Generate, manage and share all your shipping and export
                                documents on a single platform, including shipping bills (CSB
                                and PBE), eBRC, shipping labels, invoices, packing lists, CN
                                22/23 etc.
                            </div>
                        </div>
                        <div class="col-sm-1 p-0 div-arrow-parent">
                            <div class="div-arrow"></div>
                        </div>
                    </div>
                    <div class="row gst-meis">
                        <div class="col-sm-11">
                            <div class="font-weight-bold text-purple main-head-size fontFOCOBold">
                                Courier Recommendation Engine
                            </div>
                            <div class="text-muted fontFOCOLight sub-head-size">
                                Search and compare rates across India Post, DHL, Aramex and
                                other courier partners. Select that shipping products that best
                                suit your needs - price, transit time and convenience
                            </div>
                        </div>
                        <div class="col-sm-1 p-0 div-arrow-parent">
                            <div class="div-arrow"></div>
                        </div>
                    </div>
                    <div class="row price-calc">
                        <div class="col-sm-11">
                            <div class="font-weight-bold text-yellow main-head-size fontFOCOBold">
                                Customized Data Integration
                            </div>
                            <div class="text-muted fontFOCOLight sub-head-size">
                                Integrate your e-commerce website with PikMyBox, to fully
                                automate your export shipment processes, or request customized
                                excels for bulk data upload
                            </div>
                        </div>
                        <div class="col-sm-1 p-0 div-arrow-parent">
                            <div class="div-arrow"></div>
                        </div>
                    </div>
                    <div class="row look-up-tools">
                        <div class="col-sm-11">
                            <div class="font-weight-bold main-head-size fontFOCOBold">
                                Assisted Registration Services
                            </div>
                            <div class="text-muted fontFOCOLight sub-head-size">
                                Generate, manage and share all your export documents on a single
                                platform
                            </div>
                        </div>
                        <div class="col-sm-1 p-0 div-arrow-parent">
                            <div class="div-arrow"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--Our partnership
    <div class="container-fluid mt-5 pl-4 pr-5">
    <div class="container-fluid text-center mb-5">
      <img src="../../../assets/NewImages/Heading_top_icon.png" class="img-fluid mb-4" />
      <h1 class="display-6 font-weight-bold fontBRL ">Our Partnership</h1>
    </div>
  
    
    <div class="container-fluid position-relative div-150">
     
      <img src="../../../assets/NewImages/Our_Partnership.jpg" class="img-fluid image-background">
  
      <div class="container-fluid div-100 position-relative pb-4">
        <div class="row exports-partnership">
          <div class="col-sm-1 p-0 div-arrow-parent">
            <div class="div-arrow"></div>
          </div>
          <div class="col-sm-11">
            <div class="font-weight-bold text-magenta fontBRL main-head-size">
              Export Documentation Assistance
            </div>
            <div class="text-muted fontFOCO">
              Generate, manage and share all your export documents on a single
              platform, including shipping bills (CSB and PBE), eBRC, RBI, Export
              Declaration Forms, CN22/23, Shipping labels, Export Invoice , MEIS
              incentive applicatins, GST returns etc.
            </div>
          </div>
        </div>
  
        <div class="row ecommmerce-partnership">
          <div class="col-sm-1 p-0 div-arrow-parent">
            <div class="div-arrow"></div>
          </div>
          <div class="col-sm-11">
            <div class="font-weight-bold text-grey fontBRL main-head-size">
              Export Documentation Assistance
            </div>
            <div class="text-muted fontFOCO">
              Generate, manage and share all your export documents on a single
              platform, including shipping bills (CSB and PBE), eBRC, RBI, Export
              Declaration Forms, CN22/23, Shipping labels, Export Invoice , MEIS
              incentive applicatins, GST returns etc.
            </div>
          </div>
        </div>
  
        <div class="row ficci-partnership">
          <div class="col-sm-1 p-0 div-arrow-parent">
            <div class="div-arrow"></div>
          </div>
          <div class="col-sm-11">
            <div class="font-weight-bold text-purple fontBRL main-head-size">
              Export Documentation Assistance
            </div>
            <div class="text-muted fontFOCO">
              Generate, manage and share all your export documents on a single
              platform, including shipping bills (CSB and PBE), eBRC, RBI, Export
              Declaration Forms, CN22/23, Shipping labels, Export Invoice , MEIS
              incentive applicatins, GST returns etc.
            </div>
          </div>
        </div>
  
        <div class="row digilocker-partnership">
          <div class="col-sm-1 p-0 div-arrow-parent">
            <div class="div-arrow"></div>
          </div>
          <div class="col-sm-11">
            <div class="font-weight-bold text-yellow fontBRL main-head-size">
              Export Documentation Assistance
            </div>
            <div class="text-muted fontFOCO">
              Generate, manage and share all your export documents on a single
              platform, including shipping bills (CSB and PBE), eBRC, RBI, Export
              Declaration Forms, CN22/23, Shipping labels, Export Invoice , MEIS
              incentive applicatins, GST returns etc.
            </div>
          </div>
        </div>
  
        <div class="row banker-partnership">
          <div class="col-sm-1 p-0 div-arrow-parent">
            <div class="div-arrow"></div>
          </div>
          <div class="col-sm-11">
            <div class="font-weight-bold fontBRL main-head-size ">
              Export Documentation Assistance
            </div>
            <div class="text-muted fontFOCO">
              Generate, manage and share all your export documents on a single
              platform, including shipping bills (CSB and PBE), eBRC, RBI, Export
              Declaration Forms, CN22/23, Shipping labels, Export Invoice , MEIS
              incentive applicatins, GST returns etc.
            </div>
          </div>
        </div>
      </div>
  
      
      <div class="container-fluid d-flex justify-content-center" style="width: 100%;">
        <div id="india-map" class="india-map"></div>
      </div>
    </div>
  </div> -->

    <!--Application features-->
    <div class="container-fluid mt-4">
        <!--Heading-->
        <div class="container-fluid text-center">
            <h1 class="display-6 font-weight-bold fontHalfmoon word-spacing">
                Application Features
            </h1>
            <p class="centered-border mt-3"></p>
        </div>

        <!--Application features body-->
        <div class="container-fluid">
            <div class="container-fluid position-relative app-features">
                <!--Computer bg-->
                <div class="container-fluid text-center">
                    <img src="../../../assets/NewImages/Application_Feature_Computer.png"
                        class="img-fluid computer-images" />
                </div>

                <!--Number line-->
                <div class="container-fluid number-line"
                    style="margin-top: 15vh; padding-left: 10vh; padding-right: 10vh">
                    <!--Above text-->
                    <div class="row text-white text-big text-justify font-weight-bold">
                        <div class="col-sm-3 fontFOCOBold main-head-size">
                            Manage all your India Post, DHL and Aramex parcels together on
                            PikMyBox
                        </div>
                        <div class="col-sm-3 fontFOCOBold main-head-size">
                            Analytics to keep track of all your sales and shipments in real
                            time
                        </div>
                        <div class="col-sm-3 fontFOCOBold main-head-size">
                            Compare shipping rates for India Post and Courier with our online
                            price calculator tool
                        </div>
                        <div class="col-sm-3 fontFOCOBold main-head-size">
                            Enjoy all the benefits of PikMyBox on the go with the PikMyBox
                            Mobile applications on Andriod and iOS
                        </div>
                    </div>

                    <!--Number line-->
                    <div class="d-flex justify-content-center">
                        <div>
                            <div class="arrow-outer-line pt-3 pb-3">
                                <div></div>
                            </div>

                            <div class="d-flex">
                                <img src="../../../assets/NewImages/1.png" class="img-fluid number-icon" />

                                <div class="border-outer">
                                    <div></div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="arrow-outer-line pt-3 pb-3"></div>

                            <div class="d-flex">
                                <img src="../../../assets/NewImages/2.png" class="img-fluid number-icon" />

                                <div class="border-outer">
                                    <div></div>
                                </div>
                            </div>

                            <div class="arrow-outer-line pt-3 pb-3">
                                <div></div>
                            </div>
                        </div>
                        <div>
                            <div class="arrow-outer-line pt-3 pb-3">
                                <div></div>
                            </div>

                            <div class="d-flex">
                                <img src="../../../assets/NewImages/3.png" class="img-fluid number-icon" />

                                <div class="border-outer">
                                    <div></div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="arrow-outer-line pt-3 pb-3"></div>

                            <div class="d-flex">
                                <img src="../../../assets/NewImages/4.png" class="img-fluid number-icon" />

                                <div class="border-outer">
                                    <div></div>
                                </div>
                            </div>

                            <div class="arrow-outer-line pt-3 pb-3">
                                <div></div>
                            </div>
                        </div>
                        <div>
                            <div class="arrow-outer-line pt-3 pb-3">
                                <div></div>
                            </div>

                            <div class="d-flex">
                                <img src="../../../assets/NewImages/5.png" class="img-fluid number-icon" />

                                <div class="border-outer">
                                    <div></div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="arrow-outer-line pt-3 pb-3"></div>

                            <div class="d-flex">
                                <img src="../../../assets/NewImages/6.png" class="img-fluid number-icon" />

                                <div class="border-outer">
                                    <div></div>
                                </div>
                            </div>

                            <div class="arrow-outer-line pt-3 pb-3">
                                <div></div>
                            </div>
                        </div>
                        <div>
                            <div class="arrow-outer-line pt-3 pb-3">
                                <div></div>
                            </div>

                            <div class="d-flex">
                                <img src="../../../assets/NewImages/7.png" class="img-fluid number-icon" />
                            </div>
                        </div>
                    </div>

                    <!--Below text-->
                    <div class="row text-white text-justify text-big font-weight-bold">
                        <div class="col-sm-1"></div>
                        <div class="col-sm-3 fontFOCOBold main-head-size">
                            Directly upload excel files or save templates for easy data entry
                        </div>
                        <div class="col-sm-4 fontFOCOBold main-head-size">
                            Easily share shipments information with your preferred shipping
                            partners and CHAs
                        </div>
                        <div class="col-sm-3 fontFOCOBold main-head-size">
                            HS (ITC) codes, HSN codes, GST rates and notified exchange rate
                            lookup tools
                        </div>
                        <div class="col-sm-1"></div>
                    </div>
                </div>

                <!--Number line for mobile-->
                <div class="
              container-fluid
              number-line-vertical
              mt-5
              pl-4
              pr-4
              text-justify text-white text-big
            ">
                    <div class="d-flex justify-content-center">
                        <div>
                            <img src="../../../assets/NewImages/1.png" class="img-fluid number-icon" />
                            <div class="arrow-outer-line">
                                <div></div>
                            </div>
                        </div>
                        <div class="
                  vertical-line-text
                  font-weight-bold
                  fontFOCOBold
                  main-head-size
                ">
                            Manage all your India Post and DHL parcels together on PikMyBox
                        </div>
                    </div>

                    <div class="d-flex justify-content-center">
                        <div>
                            <img src="../../../assets/NewImages/2.png" class="img-fluid number-icon" />
                            <div class="arrow-outer-line">
                                <div></div>
                            </div>
                        </div>
                        <div class="
                  vertical-line-text
                  font-weight-bold
                  fontFOCOBold
                  main-head-size
                ">
                            Directly upload excel files or save templates for easy data entry
                        </div>
                    </div>

                    <div class="d-flex justify-content-center">
                        <div>
                            <img src="../../../assets/NewImages/3.png" class="img-fluid number-icon" />
                            <div class="arrow-outer-line">
                                <div></div>
                            </div>
                        </div>
                        <div class="
                  vertical-line-text
                  font-weight-bold
                  fontFOCOBold
                  main-head-size
                ">
                            Analytics to keep track of all your sales and shipments in real
                            time
                        </div>
                    </div>

                    <div class="d-flex justify-content-center">
                        <div>
                            <img src="../../../assets/NewImages/4.png" class="img-fluid number-icon" />
                            <div class="arrow-outer-line">
                                <div></div>
                            </div>
                        </div>
                        <div class="
                  vertical-line-text
                  font-weight-bold
                  fontFOCOBold
                  main-head-size
                ">
                            Easily share shipments information with your preferred shipping
                            partners and CHAs
                        </div>
                    </div>

                    <div class="d-flex justify-content-center">
                        <div>
                            <img src="../../../assets/NewImages/5.png" class="img-fluid number-icon" />
                            <div class="arrow-outer-line">
                                <div></div>
                            </div>
                        </div>
                        <div class="
                  vertical-line-text
                  font-weight-bold
                  fontFOCOBold
                  main-head-size
                ">
                            Compare shipping rates for India Post and Courier with our online
                            price calculator tool
                        </div>
                    </div>

                    <div class="d-flex justify-content-center">
                        <div>
                            <img src="../../../assets/NewImages/6.png" class="img-fluid number-icon" />
                            <div class="arrow-outer-line">
                                <div></div>
                            </div>
                        </div>
                        <div class="
                  vertical-line-text
                  font-weight-bold
                  fontFOCOBold
                  main-head-size
                ">
                            HS (ITC) codes, HSN codes, GST rates and notified exchange rate
                            lookup tools
                        </div>
                    </div>

                    <div class="d-flex justify-content-center">
                        <div>
                            <img src="../../../assets/NewImages/7.png" class="img-fluid number-icon" />
                        </div>
                        <div class="
                  vertical-line-text
                  font-weight-bold
                  fontFOCOBold
                  main-head-size
                ">
                            Enjoy all the benefits of PikMyBox on the go with the PikMyBox
                            Mobile applications on Andriod and iOS
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--Simplify Your Paperwork-->
    <div class="container-fluid">
        <!--Heading-->
        <div class="container-fluid text-center mt-5">
            <h1 class="display-6 font-weight-bold fontHalfmoon word-spacing">
                Simplify your Paperwork
            </h1>
            <p class="centered-border mt-3"></p>
        </div>

        <div class="container-fluid mt-5">
            <!--Simplify image creation-->
            <div class="text-center pl-2 pr-2">
                <img src="../../../assets/NewImages/simply.png" class="img-fluid" class="simplify-image" />
            </div>

            <!--Simplify text-->
            <div class="row ml-2">
                <div class="col-sm-1"></div>
                <div class="col-sm-5">
                    <div class="font-weight-bold fontFOCOBold main-head-size">
                        Easy to use web application for completing all your export documents
                        for Customs, RBI, DGFT, GST and India Post:
                    </div>

                    <ul class="arrow-list-icon mt-3 fontFOCOLight sub-head-size">
                        <li>Courier Shipping Bills (CSB)</li>
                        <li>Postal Bills of Export (PBE)</li>
                        <li>
                            International customs declaration forms such as CN22/ CN23 and
                            CP71/CP72
                        </li>
                        <li>RBI Export Declaration form (EDF)</li>
                        <li>Packing List</li>
                        <li>Shipping labels</li>
                        <li>Export invoices</li>
                    </ul>
                </div>
                <div class="col-sm-5">
                    <div class="font-weight-bold fontFOCOBold main-head-size">
                        Use this application for streamlining your data entry and other
                        conveniences such as:
                    </div>

                    <ul class="arrow-list-icon mt-3 fontFOCOLight sub-head-size">
                        <li>Bulk upload of Amazon and eBay order files</li>
                        <li>Customized invoice generation</li>
                        <li>Automated currency conversions</li>
                        <li>Duty and Taxes</li>
                        <li>Data download for GST returns</li>
                        <li>Completed MEIS applications</li>
                        <li>Digital and paperless Bank realization certificates (e-BRC)</li>
                    </ul>
                </div>
                <div class="col-sm-1"></div>
            </div>
        </div>
    </div>

    <!--Mobile Applications-->
    <div class="container-fluid">
        <!--Heading-->
        <div class="container-fluid text-center mt-5">
            <h1 class="display-6 font-weight-bold fontHalfmoon word-spacing">
                Mobile Application
            </h1>
            <p class="centered-border mt-3"></p>
        </div>

        <!--Mobile applicationn content-->
        <div class="container-fluid mt-5">
            <div class="row mt-5">
                <div class="col-sm-5 pb-4">
                    <img src="../../../assets/NewImages/mobile_application_img.png" class="img-fluid" />
                </div>
                <div class="col-sm-6 mt-5 ml-2">
                    <p class="font-weight-bold fontFOCOBold main-head-size">
                        Manage your parcels and documents
                    </p>
                    <ul class="arrow-list-icon fontFOCOLight sub-head-size">
                        <li>
                            View complete parcel data on the mobile app and make any edit you
                            need
                        </li>
                        <li>
                            View, edit or print any of your export documents directly from
                            your smartphone.
                        </li>
                    </ul>

                    <p class="font-weight-bold fontFOCOBold main-head-size">Tracking</p>
                    <ul class="arrow-list-icon fontFOCOLight sub-head-size">
                        <li>Track your parcel from custom clearance to final delivery</li>
                        <li>
                            Scan the Tracking ID barcode from your shipping label and link it
                            to your documents
                        </li>
                    </ul>

                    <p class="font-weight-bold fontFOCOBold main-head-size">
                        Manage your Profile
                    </p>
                    <ul class="arrow-list-icon fontFOCOLight sub-head-size">
                        <li>Customize your exporter profile</li>
                        <li>Change your contact info and reset your password</li>
                    </ul>
                </div>
                <div class="col-sm-1"></div>
            </div>
        </div>
    </div>

    <!--Better coordination between logistics partner-->
    <div class="container-fluid">
        <!--Heading-->
        <div class="container-fluid text-center mt-5">
            <h1 class="display-6 font-weight-bold fontHalfmoon word-spacing">
                Better coordination between<br />Logistics partner
            </h1>
            <p class="centered-border mt-3"></p>
        </div>

        <h5 class="container text-center fontFOCOBold mt-5 mb-5 main-head-size" style="width: 60%">
            We can onboard your selected partners including exporters, CHA's, postal
            agents, warehousing and other service providers. By integrating these
            different users, we can enhance co-ordination betweem different logistics
            partners across the supply chain.
        </h5>

        <!--logistics colored part-->
        <div class="container-fluid logistic-bg p-4 text-white text-justify">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-1"></div>
                    <div class="col-sm-5">
                        <p class="font-weight-bold fontFOCOBold main-head-size">
                            As an online retailer or exporter you can easily:
                        </p>

                        <p>
                            <span class="font-weight-bold fontFOCOBold main-head-size">Manage Orders</span><span
                                class="fontFOCOLight sub-head-size">
                                - use PikMyBox for organizing how items are boxed and labeled
                            </span>
                        </p>
                        <p>
                            <span class="font-weight-bold fontFOCOBold main-head-size">Manage Sharing</span><span
                                class="fontFOCOLight sub-head-size">
                                - only share relevant information such as shipping address,
                                product descriptions, weight and value and select which orders
                                to share with which partner</span>
                        </p>
                        <p>
                            <span class="font-weight-bold fontFOCOBold main-head-size">Reduce manual data
                                entry</span><span class="fontFOCOLight sub-head-size">
                                - integration with PikMyBox will allow automated collection of
                                relevant data from your invoice and automated creation of
                                necessary shipping labels</span>
                        </p>
                    </div>
                    <div class="col-sm-5">
                        <p class="font-weight-bold fontFOCOBold main-head-size">
                            As a logistics provider you can easily:
                        </p>

                        <p>
                            <span class="font-weight-bold fontFOCOBold main-head-size">Get support with regulatory
                                paperwork</span><span class="fontFOCOLight sub-head-size">
                                - use PikMyBox for automated completion of Postal Bill of Export
                                and Customs Declaration forms
                            </span>
                        </p>
                        <p>
                            <span class="font-weight-bold fontFOCOBold main-head-size">Plan your logistics</span><span
                                class="fontFOCOLight sub-head-size">
                                - gather relevant shipment data to support scheduling of
                                collection of goods from warehouse and drop off to post offices
                            </span>
                        </p>
                        <p>
                            <span class="font-weight-bold fontFOCOBold main-head-size">Managing sharing with
                                exporters</span><span class="fontFOCOLight sub-head-size">
                                - Use PikMyBox to share status updates with exporter at various
                                stages of shipment processing including shipment schedule
                                customs approval and creation of tracking number
                            </span>
                        </p>
                    </div>
                    <div class="col-sm-1"></div>
                </div>
            </div>
        </div>
    </div>

    <!--Integrate with PikMyBox-->
    <div class="container-fluid">
        <!--Heading-->
        <div class="container-fluid text-center mt-5">
            <h1 class="display-6 font-weight-bold fontHalfmoon word-spacing">
                Integrate with PikMyBox
            </h1>
            <p class="centered-border mt-3"></p>
        </div>

        <!--API Documentation body-->
        <div class="container-fluid pl-2 mt-5">
            <div class="row">
                <div class="col-sm-1"></div>
                <div class="col-sm-5">
                    <p class="font-weight-bold text-big fontFOCOBold main-head-size">
                        Overview
                    </p>
                    <p class="fontFOCOLight sub-head-size">
                        We can simplify your paperwork. Get automated completion of all your
                        export documents, comparison of rates across shipping partners and
                        book your shipments through our APIs
                    </p>

                    <p class="font-weight-bold text-big fontFOCOBold main-head-size">
                        How to get API key
                    </p>
                    <!--not using list here to get proper indentation required-->
                    <div class="fontFOCOLight sub-head-size">
                        1. Login your PikMyBox account
                    </div>
                    <div class="fontFOCOLight sub-head-size">2. Get your API key</div>

                    <p class="font-weight-bold text-big mt-3 fontFOCOBold main-head-size">
                        Input parameters
                    </p>
                    <!--not using list here to get proper indentation required-->
                    <div class="fontFOCOLight sub-head-size">1. invoiceNo (string)</div>
                    <div class="fontFOCOLight sub-head-size">2. goodsDetails (array)</div>

                    <p class="font-weight-bold text-big mt-3 fontFOCOBold main-head-size">
                        Example
                    </p>
                    <!--exmaple created-->
                    <div class="
                shadow
                api-example
                text-big
                font-weight-bold
                fontFOCOLight
                sub-head-size
              ">
                        <!--Injecting code to add forbidden curly, square brackets-->
                        <code>
                <pre>{{ api_example }}</pre>
              </code>
                    </div>
                </div>
                <div class="col-sm-6 mt-5 text-right">
                    <img src="../../../assets/newDesign/api_doc.png" class="img-fluid" />
                </div>
            </div>
        </div>
    </div>

    <!--Contact US-->
    <div class="container-fluid mt-5 contact-us-background mb-5">
        <!--Heading-->
        <div class="container-fluid text-center mt-5">
            <h1 class="display-6 font-weight-bold fontHalfmoon word-spacing">
                Contact Us
            </h1>
            <p class="centered-border mt-3"></p>
        </div>

        <!--Contact us body-->
        <div class="container-fluid mt-5 p-2">
            <div class="row">
                <!--Contact us text-->
                <div class="col-sm-1"></div>
                <div class="col-sm-5 text-justify fontFOCOLight sub-head-size">
                    <p>
                        Get assistance with export related questions, ranging from
                        assistance with completion of forms, availing export benefits or
                        understanding export procedures. Ou technical support team is
                        available to assist you.
                    </p>

                    <p>
                        Leave us a message here, or head to our Heldesk and report an issue,
                        suggest a new form or feature or schedule a time to speak with one
                        of our export specialists
                    </p>

                    <!--Icons-->
                    <div class="container-fluid mt-5 d-flex justify-content-between">
                        <div class="
                  d-flex
                  flex-column
                  align-items-center
                  text-center text-small
                ">
                            <i class="fa fa-map-marker contact-icon mb-3"></i>

                            <div class="fontFOCOLight">
                                246, Block A, <br />
                                Okhla Industrial Area Phase I, <br /> New Delhi 110020, India
                            </div>
                        </div>
                        <div class="
                  d-flex
                  flex-column
                  align-items-center
                  text-center text-small
                ">
                            <i class="fa fa-envelope contact-icon mb-3"></i>
                            <div class="fontFOCOLight">sales@pikmybox.com</div>
                        </div>
                        <div class="
                  d-flex
                  flex-column
                  align-items-center
                  text-center text-small
                ">
                            <i class="fa fa-phone contact-icon mb-3"></i>
                            <div class="fontFOCO">
                                011 4082 4082<br />   011 4117 9941 <br> +91 8800266887<br />Mon-Sat, 10:00 - 20:00
                            </div>
                        </div>
                    </div>

                    <!--Location-->
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14021.895509540835!2d77.2667437697754!3d28.525472899999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce1656fbb7b65%3A0x7f0e4b2c21935a5c!2sLynkit%20Solutions%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1638353009779!5m2!1sen!2sin"
                        allowfullscreen="" loading="lazy" class="mt-5 map mb-5"></iframe>
                </div>

                <!--Contact us form-->
                <div class="col-sm-5 mt-3">
                    <h3 class="
                display-6
                text-uppercase
                form-heading
                font-weight-bold
                p-2
                fontHalfmoon
              ">
                        write to us:
                    </h3>
                    <div class="container dashed-border p-5 mt-4 bg-white">
                        <p class="mt-2 fontFOCOLight sub-head-size">
                            If you would like more information or any questions. Please leave
                            us a message and we'll reply within 1 business day.
                        </p>

                        <!--Actual form-->
                        <form [formGroup]="form">
                            <div class="md-form form-sm fontFOCOLight">
                                <i class="fa fa-user prefix"></i>
                                <input type="text" [ngClass]="{
                      'is-invalid':
                        !form.controls.name.valid && form.controls.name.dirty,
                      'is-valid':
                        form.controls.name.valid && form.controls.name.dirty
                    }" formControlName="name" name="name" id="form-name"
                                    class="form-control form-control-sm input-field" required />
                                <label for="form-name">Your name</label>
                                <div class="invalid-feedback">Name is Required.</div>
                            </div>
                            <div class="md-form form-sm">
                                <i class="fa fa-university prefix"></i>
                                <input type="text" [ngClass]="{
                      'is-invalid':
                        !form.controls.cname.valid && form.controls.cname.dirty,
                      'is-valid':
                        form.controls.cname.valid && form.controls.cname.dirty
                    }" formControlName="cname" name="cname" id="form-name3" class="form-control form-control-sm"
                                    required />
                                <label for="form-name3">Company Name</label>
                                <div class="invalid-feedback">Company Name is Required.</div>
                            </div>
                            <div class="md-form form-sm">
                                <i class="fa fa-envelope prefix"></i>
                                <input type="text" [ngClass]="{
                      'is-invalid':
                        (!form.controls.email.errors?.email ||
                          form.controls.email.errors?.required) &&
                        form.controls.email.dirty,
                      'is-valid':
                        form.controls.email.errors?.email &&
                        form.controls.email.dirty
                    }" formControlName="email" name="email" id="form-email" class="form-control form-control-sm"
                                    required />
                                <label for="form-email">Your email</label>
                                <div class="invalid-feedback">Invalid Email ID.</div>
                            </div>
                            <div class="md-form form-sm">
                                <i class="fa fa-phone prefix"></i>
                                <input type="text" [ngClass]="{
                      'is-invalid':
                        (!form.controls.mobile.errors?.mobile ||
                          form.controls.mobile.errors?.required) &&
                        form.controls.mobile.dirty,
                      'is-valid':
                        form.controls.mobile.errors?.mobile &&
                        form.controls.mobile.dirty
                    }" formControlName="mobile" name="mobile" id="form-Subject" class="form-control form-control-sm"
                                    required />

                                <label for="form-Subject">Mobile</label>
                                <div class="invalid-feedback">Invalid Mobile No.</div>
                            </div>
                            <div class="md-form form-sm">
                                <i class="fa fa-comments prefix"></i>
                                <input type="text" formControlName="message" name="message" id="form-message"
                                    class="form-control form-control-sm message-field" required />

                                <label for="form-message">Message</label>
                                <div class="invalid-feedback">Invalid Message</div>
                            </div>
                        </form>
                        <div class="center-button fontBRL">
                            <button [disabled]="
                    !(
                      form.controls.name.valid &&
                      form.controls.cname.valid &&
                      form.controls.email.errors?.email &&
                      form.controls.mobile.errors?.mobile
                    )
                  " class="btn mt-4 mb-3 submit-button fontFOCO" type="reset" data-dismiss="modal"
                                (click)="submitForm(form.value)">
                                Submit
                            </button>
                        </div>
                    </div>

                    <!-- <div class="position-relative d-flex align-items-center mt-5 arrow-position">
         
            
                <div class="text-weight-bold follow-us-block pl-2 fontFOCOBold">
                  <div>
                    <div> Follow Us :</div>
                    <div class="d-flex">
                      <a href="https://www.facebook.com/pikmybox/" target="new"><i class="fa fa-facebook-official"
                          style="color: black; font-size: 25px"></i></a>
                      <a href="https://www.instagram.com/pikmybox/" target="new" class="ml-2"><i class="fa fa-instagram"
                          aria-hidden="true" style="color: black; font-size: 25px"></i></a>
  
                      <a href="https://www.youtube.com/channel/UCL4kJ7j87def-F1RUN-Iidw/featured" target="new"
                        class="ml-2"><i class="fa fa-youtube" aria-hidden="true"
                          style="color: black; font-size: 25px"></i></a>
                      <a href="https://twitter.com/PikMyBox" target="new" class="ml-2"><i class="fa fa-twitter"
                          aria-hidden="true" style="color: black; font-size: 25px"></i></a>
                    </div>
                  </div>
              </div>
              <div style="width: 60%" class="pl-2 mt-5">
                <span class="font-weight-bold underline-privacy fontFOCOBold sub-head-size">Privacy Policy:
                </span><span class="fontFOCOLight sub-head-size">
                  Your data is secured with us. We do not share data to any third
                  party.
                </span>
                <p class="font-weight-bold mt-4 underline-privacy">
                  <span> <i class="fa fa-copyright"></i> </span><span class="fontFOCOBold sub-head-size">PikMyBox
                    2018</span>
                </p>
  
              </div>
            </div> -->

                    <div class="position-relative margin">
                        <div class="text-weight-bold pl-2 fontFOCOBold">
                            <div>
                                <div>Follow Us :</div>
                                <div class="d-flex">
                                    <a href="https://www.facebook.com/pikmybox/" target="new"><i
                                            class="fa fa-facebook-official"
                                            style="color: black; font-size: 25px"></i></a>
                                    <a href="https://www.instagram.com/pikmybox/" target="new" class="ml-2"><i
                                            class="fa fa-instagram" aria-hidden="true"
                                            style="color: black; font-size: 25px"></i></a>

                                    <a href="https://www.youtube.com/channel/UCL4kJ7j87def-F1RUN-Iidw/featured"
                                        target="new" class="ml-2"><i class="fa fa-youtube" aria-hidden="true"
                                            style="color: black; font-size: 25px"></i></a>
                                    <a href="https://twitter.com/PikMyBox" target="new" class="ml-2"><i
                                            class="fa fa-twitter" aria-hidden="true"
                                            style="color: black; font-size: 25px"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="pl-2 mt-5">
                            <span class="
                    font-weight-bold
                    underline-privacy
                    fontFOCOBold
                    sub-head-size
                  ">Privacy
                                Policy: </span><span class="fontFOCOLight sub-head-size">
                                Your data is secured with us. We do not share data to any third
                                party.
                            </span>
                            <p class="font-weight-bold mt-3 underline-privacy">
                                <span> <i class="fa fa-copyright"></i> </span><span
                                    class="fontFOCOBold sub-head-size">PikMyBox
                                    2018</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-1"></div>
            </div>
        </div>
    </div>
</div>